.tr-modal{
  display: block; /* Скрыто по умолчанию */
  position: fixed; /* Оставаться на месте */
  z-index: 1; /* Сидеть на вершине */
  left: 0;
  top: 0;
  width: 100%; /* Полная ширина */
  height: 100%; /* Полная высота */
  overflow: auto; /* Включите прокрутку, если это необходимо */
  background-color: rgb(0,0,0); /* Цвет запасной вариант */
  background-color: rgba(0,0,0,0.4); /* Черный с непрозрачностью */
  &-content {
    background-color: #eef5f8;
    position: relative;
    border-radius: 3px;
    margin: 15% auto; 
    padding: 30px 20px;
    border: 1px solid #888;
    width: 80%; 
    display: flex;
    align-items: center;
    justify-content: center;
    .close {
      color: #000;
      // float: right;
      position: absolute;
      top: -10px;
      right: 0;
      margin-right: 10px;
      font-size: 48px;
      font-weight: bold;
      cursor: pointer;
      transform: scale(1);
      &:hover{
        transform: scale(1.1);
        transition: all .5s ease-out;
      }
    }
    img{
      max-width: 100%;
    }
    table{
      width: 100%;
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      border: 1px solid #dddddd;
      border-collapse: collapse; 
      box-shadow: 5px 5px 5px rgb(133, 129, 129);
      tr{
        .save-td{
          cursor: pointer;
          transform: scale(1);
          &:hover{
            transition: all .3s ease-out;
            transform: scale(1.2);
          }
        }
      }
      th{
        font-weight: bold;
        padding: 10px;
        background: #4e4e4e;
        color: #fff;
        border: 1px solid #dddddd;
        // cursor: pointer;
      }
      td.attached{
        div img{
          margin-top: 10px;
          cursor: pointer;
          &:hover{
            transform: scale(1.5);
            transition: all .5s ease;
          }
        }
      }
      td{
        border: 1px solid #dddddd;
        padding: 20px 5px;
        text-align: center;
        .react-calendar{
          background-color: #eef5f8;
          margin: auto;
          width: 190px;
          border: none;
          display: flex;
          flex-direction: column;
          align-items: center;
          img{
            transform: scale(1);
            margin-bottom: 10px;
            cursor: pointer;
          }
          img:hover{
            transition: all .3s ease-out;
            transform: scale(1.3);
          }
        }
        input[type="file"] {
          display: none;
        }
        .custom-file-upload {
          border: 1px solid #ccc;
          display: inline-block;
          padding: 6px 12px;
          cursor: pointer;
          &:hover{
            background-color: rgb(95, 93, 93);
            color: #fff;
          }
        }
      }
    }
  }

}