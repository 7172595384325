.form{
  width: 600px;
  margin: 50px auto;
  padding: 30px 30px 50px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(69, 150, 241);
  // background-color: #4a1d6d;
  border-radius: 5px;
  color: #fff;
  &__header{
    text-align: center;
  }
  &__body{
    background-color: rgb(11, 80, 158);
    margin-top: 20px;
    padding: 50px;
    border-radius: 11px;
  }
  .actions{
    margin-top: 35px;
    text-align: center;
    .btn{
      padding: 15px 25px;
      font-size: 16px;
      border-radius: 5px;
      border: 1px solid #fff;
      outline: none;
      cursor: pointer;
      color:#fff;
      background-color: rgb(11, 80, 158);
      &:hover{
        // background-color: rgb(5, 38, 77);
        transition: all .4s;
        font-weight: bold;
        box-shadow: 1px 1px 1px 1px #fff;
      }
    }
    .btn-disabled{
      padding: 15px 25px;
      font-size: 16px;
      border-radius: 5px;
      border: 0;
      outline: none;
    }
  }
  .err-auth{
    text-align: center;
    color: rgb(207, 14, 14);
    margin-top: 10px;
  }
}