*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  border: 0;
}
html,body {
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
}
.container{
  max-width: 980px;
  margin: 0 auto;
  height: 100%;
}
