.header{
  width: 100%;
  height: 80px;
  top: 0;
  left: auto;
  z-index: 50;
  // background-color: rgb(39, 38, 38);
  background-color: #4a1d6d;
  border-radius: 4px;
}
.header__body {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 20px;
  height: 80px;
  color: #fff;
  .header__logo{
    font-weight: bold;
    font-size: 19px;
  }
  .header__burger{
    display: none;
  }
  .header__list{
    display: flex;
    cursor: pointer;
    &-item{
      list-style: none;
      margin: 0 0 0 20px;
    }
    &-item:after{
      content: '';
      display: block;
      width: 100%;
      background-color: #ccc;
      height: 1px;
      transform: scale(0);
      transition: all .3s;
    }
    &-item a:hover::after{
      transform: scale(1);
    }
    &-item .active{
      text-shadow: 1px 0px 5px #fff;
      text-decoration: underline;
    }
    &-link{
      text-decoration: none;
      text-transform: uppercase;
      color: #fff;
      font-size: 15px;
      &:hover{
        text-decoration: underline;
      }
    }
  }
}
@media (max-width: 768px){
  .header {
    padding: 0 10px;
    font-size: 25px;
  }
  .header__body{
    align-items: center;
    .header__logo{
    }
    .header__burger{
      display: block;
      position: relative;
      width: 30px;
      height: 20px;
      cursor: pointer;
    }
    .header__burger span{
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      left: 0;
      top: 9px;
      transition: all 0.4s ease 0s;
    }
    .header__burger.active span{
      transform: scale(0);
    }
    .header__burger::before,
    .header__burger::after{
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      left: 0;
      background-color: #fff;
      transition: all 0.6s ease 0s;
    }
    .header__burger::before{
      top: 0;
    }
    .header__burger.active::before{
      top: 9px;
      transform: rotate(45deg);
      transition: all 0.6s ease 0s;
    }
    .header__burger::after{
      bottom: 0;
    }
    .header__burger.active::after{
      bottom: 9px;
      transform: rotate(-45deg);
      transition: all 0.6s ease 0s;
    }
    .header__menu{
      position: fixed;
      overflow: auto;
      text-align: center;
      top: -100%;
      left: 50%;
      transform: translate(-50%, 0);
      width: 350px;
      margin: 30px auto;
      border-radius: 4px;
      height: auto;
      background-color: rgb(39, 38, 38);
      transition: all 0.4s ease 0s;
    }
    .header__menu.active{
      top: 50px;
    }
    .header__list{
      display: block;
      font-size: 35px;
    }
    .header__list-item{
      margin: 0;
    }
  }
}

