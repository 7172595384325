.scanmodal{
  display: block; /* Скрыто по умолчанию */
  position: fixed; /* Оставаться на месте */
  z-index: 111; /* Сидеть на вершине */
  left: 0;
  top: 0;
  width: 100%; /* Полная ширина */
  height: 100%; /* Полная высота */
  overflow: auto; /* Включите прокрутку, если это необходимо */
  background-color: rgb(0,0,0); /* Цвет запасной вариант */
  background-color: rgba(0,0,0,0.4); /* Черный с непрозрачностью */
  &-content {
    background-color: #fefefe;
    border-radius: 3px;
    margin: 15% auto; 
    padding: 30px 20px;
    border: 1px solid #888;
    width: 80%; 
    display: flex;
    align-items: center;
    justify-content: center;
    img{
      max-width: 100%;
    }
  }
  .close {
    color: rgb(241, 237, 237);
    float: right;
    margin-right: 10px;
    font-size: 48px;
    font-weight: bold;
    cursor: pointer;
    transform: scale(1);
    &:hover{
      transform: scale(1.1);
      transition: all .5s ease-out;
    }
  }
}