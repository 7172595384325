.table-pcrtest {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-collapse: collapse;
  box-shadow: 5px 5px 5px rgb(133, 129, 129);
  tr {
    .save-td,
    .delete-td {
      cursor: pointer;
      transform: scale(1);
      &:hover {
        transition: all 0.3s ease-out;
        transform: scale(1.2);
      }
    }
  }
  th {
    font-weight: bold;
    padding: 10px;
    background: #4e4e4e;
    color: #fff;
    border: 1px solid #dddddd;
    // cursor: pointer;
  }
  .attached {
    background-color: rgb(208, 235, 208);
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    // padding: 0;
    img {
      // max-width: 100%;
      margin-top: 10px;
      width: 30px;
      height: 30px;
      cursor: pointer;
      transform: scale(1);
      &:hover {
        transform: scale(1.25);
        transition: all 0.5s ease;
      }
    }
  }
  td.edit-td {
    padding: 0 15px;
    cursor: pointer;
    &:hover {
      transition: all 0.5s ease;
      transform: scale(1.2);
    }
  }
  td {
    border: 1px solid #dddddd;
    padding: 2px 5px;
    text-align: center;
    .react-calendar {
      margin: auto;
      width: 190px;
      border: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        transform: scale(1);
        margin-bottom: 10px;
        cursor: pointer;
      }
      img:hover {
        transition: all 0.3s ease-out;
        transform: scale(1.3);
      }
    }
    // .attached{
    //   background-color:rgb(208, 235, 208);
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;
    //   cursor: pointer;
    //   height: 100%;
    //   img{
    //     // max-width: 100%;
    //     margin-top: 10px;
    //     width: 30px;
    //     height: 30px;
    //     cursor: pointer;
    //     transform: scale(1);
    //     &:hover{
    //       transform: scale(1.25);
    //       transition: all .5s ease;
    //     }
    //   }
    // }
    input[type="file"] {
      display: none;
    }
    .custom-file-upload {
      border: 1px solid #ccc;
      display: inline-block;
      padding: 6px 12px;
      cursor: pointer;
      &:hover {
        background-color: rgb(95, 93, 93);
        color: #fff;
      }
    }
  }
}
.loader-container {
  width: 100%;
  text-align: center;
}
