.footer{
  width: 100%;
  // height: 80px;
  // background-color: rgb(107, 115, 117);
  background-color: #4a1d6d;
  color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 10px;
  .logo{
    padding: 0 10px;
    img{
      width: 100px;
    }
  }
  .footer-title{
    font-size: 21px;
    font-weight: bold;
  }
  .contacts{
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    &-email, &-phone{
      display: flex;
      align-items: center;
      span{
        margin-left: 10px;
      }
    }
    &-email{
      transform: scale(1);
      span{
        a{
          color: #fff;
        }
      }
      &:hover{
        transform: scale(1.15);
        transition: all .5s ease;
      }
    }
  }
}