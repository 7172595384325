.content{
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.userpage{
  width: 950px;
  margin: 0 auto;
  padding: 15px;
  flex: 1 1 auto;
  .loader-block{
    text-align: center;
  }
  .title{
    text-align: center;
    margin-bottom: 10px;
    color: #4e4e4e;
    font-size: 17px;
  }
  .global-user{
    font-weight: bold;
    background-color: rgb(234, 236, 236);
    box-shadow: 5px 5px 5px rgb(189, 186, 186);
    color: #000;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 20px;
    .fio{
      // margin-top: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 21px;
      img{
        margin-left: 10px;
        transform: scale(1);
        cursor: pointer;
        &:hover{
          transition: all .5s ease;
          transform: scale(1.35);
        }
      }
    }
    .birthday{
      margin-top: 20px;
      font-size: 18px;
      text-align: center;
      img{
        margin-left: 10px;
        transform: scale(1);
        cursor: pointer;
        &:hover{
          transition: all .5s ease;
          transform: scale(1.35);
        }
      }
    }
  }
  // .table{
  //   width: 100%;
  //   margin: 0 auto;
  //   margin-bottom: 20px;
  //   border: 1px solid #dddddd;
  //   border-collapse: collapse; 
  //   box-shadow: 5px 5px 5px rgb(133, 129, 129);
  //   tr{
  //     .save-td{
  //       cursor: pointer;
  //       transform: scale(1);
  //       &:hover{
  //         transition: all .3s ease-out;
  //         transform: scale(1.2);
  //       }
  //     }
  //     .attached{
  //       background-color:rgb(208, 235, 208);
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       img{
  //         // max-width: 100%;
  //         margin-top: 10px;
  //         width: 30px;
  //         height: 30px;
  //         cursor: pointer;
  //         transform: scale(1);
  //         &:hover{
  //           transform: scale(1.25);
  //           transition: all .5s ease;
  //         }
  //       }
  //     }
  //   }
  //   th{
  //     font-weight: bold;
  //     padding: 10px;
  //     background: #4e4e4e;
  //     color: #fff;
  //     border: 1px solid #dddddd;
  //     cursor: pointer;
  //   }
  //   td{
  //     border: 1px solid #dddddd;
  //     padding: 2px 5px;
  //     text-align: center;
  //     .react-calendar{
  //       margin: auto;
  //       width: 190px;
  //       border: none;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       img{
  //         transform: scale(1);
  //         margin-bottom: 10px;
  //         cursor: pointer;
  //       }
  //       img:hover{
  //         transition: all .3s ease-out;
  //         transform: scale(1.3);
  //       }
  //     }
  //     input[type="file"] {
  //       display: none;
  //     }
  //     .custom-file-upload {
  //       border: 1px solid #ccc;
  //       display: inline-block;
  //       padding: 6px 12px;
  //       cursor: pointer;
  //       &:hover{
  //         background-color: rgb(95, 93, 93);
  //         color: #fff;
  //       }
  //     }
  //   }
  // }
  .go-to-full-list{
    // text-align: right;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transform: scale(1);
    cursor: pointer;
    &:hover{
      transform: scale(1.05);
      transition: all .7s ease;
    }
  }
  .plus-actions{
    text-align: center;
    cursor: pointer;
    transform: scale(1);
    &:hover{
      transition: all .7s ease;
      transform: scale(1.15);
    }
  }
  // .actions{
  //   text-align: center;
  //   .btn{
  //     padding: 15px 55px;
  //     border-radius: 3px;
  //     background: #4e4e4e;
  //     color: #fff;
  //     font-size: 18px;
  //     cursor: pointer;
  //     transform: scale(1);
  //     transition: transform .2s ease-out;
  //     &:hover{
  //       transform: scale(1.05);
  //       transition: transform .3s ease-in;
  //     }
  //   }
  //   .btn-disabled{
  //     padding: 15px 55px;
  //     font-size: 18px;
  //     border-radius: 3px;
  //     background: #7e7d7d;
  //     border: 0;
  //     outline: none;
  //   }
  // }
}
  .paginations{
    text-align: center;
    padding: 2px;
    cursor: pointer;
    span{
      padding: 2px;
    }
    .active-page{
      font-weight: bold;
      font-size: 21px;
    }
  }
.react-calendar__tile--active{
  color: rgb(46, 29, 202);
  font-weight: bold;
}