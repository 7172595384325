.birthdaymodal{
  display: block; 
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0,0,0); /* Цвет запасной вариант */
  background-color: rgba(0,0,0,0.7); /* Черный с непрозрачностью */
  .birthdaymodal__body{
    display: flex;
    flex-direction: column;
    align-items: center;
    // margin-left: 600px;
    justify-content: center;
    margin-top: 155px;
    color: #fff;
    font-weight: bold;
    width: 100%;
    form{
      width: 650px;
      // text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input{
        width: 300px;
        padding: 10px 15px;
        margin: 10px 0 20px 0;
        border-radius: 5px;
        border: 1px solid #fff;
        outline: none;
        transform: scale(1);
        transition: transform 0.3s ease 0s;
        background-color: rgb(11, 80, 158);
        color: #fff;
        &:focus{
          transform: scale(1.05);
        }
        &::-moz-placeholder{
          color: #fff
        }
        &::-webkit-input-placeholder{
          color: #fff;
        }
      }
      
    }
  }
  .actions{
    .btn{
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
      transform: scale(1);
      cursor: pointer;
      &:hover{
        transition: all .5s ease;
        transform: scale(1.1);
      }
    }
    .btn-success{
      background-color: #4c53af;
      border: none;
      color: white;
    }
    .btn-exit{
      background-color: #afa04c;
      border: none;
      color: white;
      margin-left: 65px;
    }
    .btn-disabled{
      border: none;
      color: white;
      padding: 15px 32px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 16px;
    }
  }
  .close {
    color: rgb(241, 237, 237);
    // float: right;
    // margin-right: 10px;
    font-size: 48px;
    font-weight: bold;
    cursor: pointer;
    transform: scale(1);
    &:hover{
      transform: scale(1.1);
      transition: all .5s ease-out;
    }
  }
}