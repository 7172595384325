  .tests-table{
    width: 100%;
    margin: 10px auto 20px auto;
    border: 1px solid #dddddd;
    border-collapse: collapse; 
    box-shadow: 5px 5px 5px rgb(133, 129, 129);
    tr{
      .save-td{
        cursor: pointer;
        transform: scale(1);
        &:hover{
          transition: all .3s ease-out;
          transform: scale(1.2);
        }
      }
    }
    th{
      font-weight: bold;
      padding: 15px;
      background: #4e4e4e;
      color: #fff;
      border: 1px solid #dddddd;
      // cursor: pointer;
    }
    .attached{
      background-color:rgb(208, 235, 208);
      display: flex;
      flex-direction: column;
      align-items: center;
      img{
        // max-width: 100%;
        margin-top: 10px;
        width: 30px;
        height: 30px;
        cursor: pointer;
        transform: scale(1);
        &:hover{
          transform: scale(1.25);
          transition: all .5s ease;
        }
      }
    }
    td{
      border: 1px solid #dddddd;
      padding: 2px 5px;
      text-align: center;
    }
  }
  // .paginations{
  //   text-align: center;
  //   padding: 2px;
  //   cursor: pointer;
  //   span{
  //     padding: 2px;
  //   }
  //   .active-page{
  //     font-weight: bold;
  //     font-size: 23px;
  //   }
  // }
  .loader-block{
    text-align: center;
    position: absolute;
    z-index: 1999;
    left: 50%;
    top: 230px;
  }
  .btn{
    padding: 10px;
    background: none;
    border: 1px solid #000;
    margin-top: 30px;
    cursor: pointer;
    border-radius: 3px;
    &:hover{
      background-color: #4e4e4e;
      color: white;
      font-weight: bold;
    }
  }