.field-wrapper{
  display: flex;
  margin: 15px 0;
  align-items: center;
  input{
    flex: 0 0 auto;
    padding: 10px 15px;
    border-radius: 5px;
    border: 1px solid #fff;
    outline: none;
    transform: scale(1);
    transition: transform 0.3s ease 0s;
    background-color: rgb(11, 80, 158);
    color: #fff;
    &:focus{
      transform: scale(1.05);
    }
    &::-moz-placeholder{
      color: #fff
    }
    &::-webkit-input-placeholder{
      color: #fff;
    }
  }
  .label{
    flex: 0 0 80px;
    margin-right: 35px;
    text-align: center;
  }
}
.field-wrapper:before{
  content: '*';
  position: relative;
  top: 3px;
  left: 95px;
  color: red;
}

.error{
  text-align: center;
  color: rgb(15, 15, 15);
  font-size: 15px;
}
input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px rgb(11, 80, 158) inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #fff;
}