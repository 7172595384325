.admin-page-tests{
  .loader-container{
    width: 100%;
    text-align: center;
  }
  // h2{
  //   text-align: center;
  //   margin: 10px 0 20px 0;
  // }
  // .actions-to-get{
  //   margin-top: 30px;
  //   display: flex;
  //   justify-content: space-evenly;
  //   .get-tests, .get-vaccinations{
  //     cursor: pointer;
  //   }
  //   .get-tests.action, .get-vaccinations.action{
  //     text-decoration: underline;
  //     font-weight: bold;
  //   }
  // }
  // table{
  //   width: 100%;
  //   margin: 030px auto 20px auto;
  //   border: 1px solid #dddddd;
  //   border-collapse: collapse; 
  //   box-shadow: 5px 5px 5px rgb(133, 129, 129);
  //   tr{
  //     .save-td{
  //       cursor: pointer;
  //       transform: scale(1);
  //       &:hover{
  //         transition: all .3s ease-out;
  //         transform: scale(1.2);
  //       }
  //     }
  //     .attached{
  //       background-color:rgb(208, 235, 208);
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       img{
  //         // max-width: 100%;
  //         margin-top: 10px;
  //         width: 30px;
  //         height: 30px;
  //         cursor: pointer;
  //         transform: scale(1);
  //         &:hover{
  //           transform: scale(1.25);
  //           transition: all .5s ease;
  //         }
  //       }
  //     }
  //   }
  //   th{
  //     font-weight: bold;
  //     padding: 10px;
  //     background: #4e4e4e;
  //     color: #fff;
  //     border: 1px solid #dddddd;
  //     cursor: pointer;
  //   }
  //   td{
  //     border: 1px solid #dddddd;
  //     padding: 2px 5px;
  //     text-align: center;
  //     // .react-calendar{
  //     //   margin: auto;
  //     //   width: 190px;
  //     //   border: none;
  //     //   display: flex;
  //     //   flex-direction: column;
  //     //   align-items: center;
  //     //   img{
  //     //     transform: scale(1);
  //     //     margin-bottom: 10px;
  //     //     cursor: pointer;
  //     //   }
  //     //   img:hover{
  //     //     transition: all .3s ease-out;
  //     //     transform: scale(1.3);
  //     //   }
  //     // }
  //     // input[type="file"] {
  //     //   display: none;
  //     // }
  //     // .custom-file-upload {
  //     //   border: 1px solid #ccc;
  //     //   display: inline-block;
  //     //   padding: 6px 12px;
  //     //   cursor: pointer;
  //     //   &:hover{
  //     //     background-color: rgb(95, 93, 93);
  //     //     color: #fff;
  //     //   }
  //     // }
  //   }
  // }
  .paginations{
    text-align: center;
    padding: 2px;
    cursor: pointer;
    span{
      padding: 2px;
    }
    .active-page{
      font-weight: bold;
      font-size: 21px;
    }
  }
}